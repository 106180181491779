export type ProductArgs = {
  code: string;
  amount: number;
  paid: number;
  earned: number;
};

export class Product {
  code: string;
  amount: number;
  paid: number;
  earned: number;
  constructor(l: ProductArgs) {
    this.code = l.code;
    this.amount = l.amount;
    this.paid = l.paid;
    this.earned = l.earned;
  }
}