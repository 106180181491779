import { Product } from "@/entities/product";

import ProductRepository from "@/repositories/local/product_repository";
import { getDecryptedString, getEncryptedString } from "@/util/encrypt";

export default class LocalProductRepository implements ProductRepository {
  getProduct(): Product {
    const json = sessionStorage.getItem("product"); 
    if (json) {
      const productJson = JSON.parse(json);
      if (productJson) {
        const code = getDecryptedString(productJson.code);
        const product = new Product(
          { 
            code: code,
            amount: productJson.amount,
            paid: productJson.paid,
            earned: productJson.earned,
          }
        );
        return product;
      }
    }
    throw Error("処理中にエラーが発生しました。");
  }

  saveProduct(product: Product): void {
    product.code = getEncryptedString(product.code);
    sessionStorage.setItem("product", JSON.stringify(product));
    return;
  }
}
