import { defineComponent, h, ref, onMounted } from "vue";
import { useRepositories } from "@/infra/api/injects";
import { CommunicationState } from "@/type";
import { Product } from "@/entities/product";
import { useRouter } from "vue-router";
import { BaseError, InternalError, UnknownError } from "@/entities/errors";
const GetFan = (WrappedComponent: any) =>
  defineComponent({
    name: "WalletContainer",
    setup() {
      const communicationState = ref<CommunicationState>(1);
      const repositories = useRepositories();
      const balance = ref<number>(0);
      const isLoggedIn = ref<boolean>();
      const products = ref<Array<Product>>([]);
      const router = useRouter();
      async function getProducts(): Promise<Array<Product>> {
        return repositories.value.WalletManagerRepository.getProducts();
      }

      async function checkLogin(): Promise<boolean> {
        try {
          await repositories.value.FanRepository.getFan();
          isLoggedIn.value = true;
          return true;
        } catch (e) {
          isLoggedIn.value = false;
          return false;
        }
      }

      async function saveProduct(product: Product): Promise<void> {
        const isLoggedIn = await checkLogin();
        if (!isLoggedIn) return;
        repositories.value.LocalProductRepository.saveProduct(product);
        router.push({ name: "PaymentScreen" });
      }

      async function getBalance(): Promise<number> {
        return repositories.value.FanRepository.getBalance();
      }

      onMounted(async () => {
        communicationState.value = 2;
        try {
          await checkLogin();
          balance.value = await getBalance();
          products.value = await getProducts();
        } catch (e) {
          if (e instanceof InternalError) {
            router.push({ name: "ServerErrorScreen" });
            return;
          }
        } finally {
          communicationState.value = 1;
        }
      });
      return {
        balance,
        communicationState,
        products,
        saveProduct,
        isLoggedIn,
      };
    },
    render() {
      return h(WrappedComponent, {
        balance: this.balance,
        communicationState: this.communicationState,
        products: this.products,
        isLoggedIn: this.isLoggedIn,
        onSaveProduct: (product: Product) => this.saveProduct(product),
      });
    },
  });
export default GetFan;
