
import { defineComponent, computed, watch, ref, PropType } from "vue";
import BaseRoundRectButton from "@/components/atoms/BaseRoundRectButton.vue";
import SignInMaterial from "@/components/common_presentational/SignInModal.vue";
import SignInContainer from "@/components/container/SignInContainer";
import { Product } from "@/entities/product";
import { CommunicationState } from "@/type";
import Loading from "@/components/atoms/Loading.vue";
import CoinSVG from "@/assets/icon/coin.svg";
const SignInModal = SignInContainer(SignInMaterial);
export default defineComponent({
  name: "Shop",
  components: {
    BaseRoundRectButton,
    SignInModal,
    Loading,
    CoinSVG,
  },
  props: {
    products: {
      type: Array as PropType<Product[]>,
      required: true,
    },
    communicationState: {
      type: Object as () => CommunicationState,
      default: 1,
      required: true,
    },
    balance: {
      type: Number,
      default: 0,
      required: true,
    },
    isLoggedIn: {
      type: Boolean,
      default: true,
      required: true,
    },
  },
  emits: {
    saveProduct(product: Product) {
      if (product != null) return true;
    },
  },
  setup(props, { emit }) {
    console.log("products", props.products);
    const isLoading = ref<boolean>();
    const showBalance = ref<boolean>();
    const showSignInModal = ref<boolean>();
    const formatNumber = (point: number) => {
      return point.toString().replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    };
    function toPaymentPage(product: Product) {
      emit("saveProduct", product);
    }
    const price = computed(
      () =>
        function (amount: number) {
          return amount.toString().replace(/(\d)(?=(\d{3})+$)/g, "$1,") + "円";
        }
    );
    watch(
      () => props.communicationState,
      () => {
        if (props.communicationState === 2) {
          isLoading.value = true;
        } else {
          isLoading.value = false;
        }
      }
    );
    watch(
      () => props.isLoggedIn,
      () => {
        if (!props.isLoggedIn) {
          showSignInModal.value = true;
          showBalance.value = false;
        } else {
          showBalance.value = true;
        }
      },
      { immediate: true }
    );
    return {
      isLoading,
      showSignInModal,
      showBalance,
      toPaymentPage,
      price,
      formatNumber,
    };
  },
});
